<template>
  <div class="new-shipment-quotation-price-header tw-flex tw-items-center">
    <div
      v-if="hasCheck"
      :class="{
        'tw-bg-white tw-text-blue-500': active,
        'tw-border tw-border-solid tw-border-blue-500 tw-bg-white': !active
      }"
      class="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-h-4 tw-w-4 tw-mr-2"
      data-test="checkbox"
    >
      <ui-material-icon
        v-if="active"
        class="tw-text-sm"
        name="done"
        data-test="icon"
      />
    </div>
    <div
      v-text="title"
      data-test="title"
    />
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentQuotationPriceHeader
   * @param {string} title
   * @param {boolean} [active=false]
   * @param {boolean} [hasCheck=false]
   */
  export default {
    name: 'NewShipmentQuotationPriceHeader',
    props: {
      title: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      },
      hasCheck: {
        type: Boolean,
        default: false
      },
      customOfferHighlightLabel: {
        type: String,
        default: 'New'
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-quotation-price-header .custom-offer-tag {
    background: white;
    border-radius: 2px;
    color: $info;
    text-align: center;
    width: 80px;
  }

</style>
