<template>
  <div
    v-if="getExternalRequest.uuid && getExternalRequest.external_id"
    class="tw-bg-yellow-300 tw-rounded tw-text-yellow-700 tw-px-4 tw-py-2"
    data-test="external-request-info"
  >
    {{ $t('new-shipment.labels.deposit.external_request') }} <span class="tw-font-bold">{{ `${getUserInfos.companies[0].name} - #${getExternalRequest.external_id}` }}</span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentFromExternalRequestInfo
   */
  export default defineComponent({
    name: 'NewShipmentFromExternalRequestInfo',
    computed: {
      ...mapGetters('externalRequests', [
        'getExternalRequest'
      ]),
      ...mapGetters('auth', [
        'getUserInfos'
      ])
    }
  })
</script>
