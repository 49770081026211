var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-sidebar"},[_c('h2',{staticClass:"new-shipment-sidebar__title tw-font-normal mb-4"},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.titles.your_quote'))+" ")]),_c('new-shipment-from-external-request-info',{staticClass:"tw-mb-5"}),_c('div',{staticClass:"new-shipment-sidebar__navigation-groups"},[_c('new-shipment-sidebar-group',{staticClass:"tw-mb-8"},_vm._l((['pickup', 'delivery']),function(direction){return _c('new-shipment-sidebar-group-item',{key:direction,attrs:{"name":_vm.$t(("new-shipment.titles.address." + direction)),"to":{
          name: 'NewShipmentAddress',
          params: {
            direction: direction
          }
        },"disabled":direction === 'delivery' && !_vm.isPickupAddressCompleted},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ui-ctk-icon',{class:[
              direction === 'pickup' ? 'tw-text-blue-500' : 'tw-text-green-500'
            ],attrs:{"name":"marker"}})]},proxy:true}],null,true)},[(_vm.getAddress(direction) && _vm.getAddress(direction).city)?_c('new-shipment-sidebar-group-item-address',{attrs:{"address":_vm.getAddress(direction)}}):_vm._e()],1)}),1),_c('new-shipment-sidebar-group',{staticClass:"tw-mb-8"},[_c('new-shipment-sidebar-group-item',{attrs:{"name":_vm.$t('new-shipment.titles.goods_summary'),"to":{
          name: 'NewShipmentGoods'
        },"disabled":!_vm.isAddressesCompleted},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ui-ctk-icon',{staticClass:"tw-text-blue-500",attrs:{"name":"dimension"}})]},proxy:true}])},[(!!_vm.getLoad.type && !!_vm.getLoad.height && !!_vm.getLoad.weight)?_c('shipment-load-summary',{attrs:{"load":_vm.getLoad,"pallets":_vm.getLoad.pallets}}):_vm._e()],1)],1),_c('new-shipment-sidebar-group',{staticClass:"tw-mb-8",attrs:{"to":{
        name: 'NewShipmentHandling'
      },"disabled":!_vm.isGoodsCompleted}},_vm._l((['pickup', 'delivery']),function(direction){return _c('new-shipment-sidebar-group-item',{key:direction,attrs:{"name":_vm.$t(("new-shipment.titles.handling." + direction))},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ui-ctk-icon',{class:[
              direction === 'pickup' ? 'tw-text-blue-500' : 'tw-text-green-500'
            ],attrs:{"name":"hatch"}})]},proxy:true}],null,true)},[(_vm.getHandling(direction).driver)?_c('new-shipment-sidebar-group-item-handling',{attrs:{"direction":direction,"driver":_vm.getHandling(direction).driver,"tail-lift":_vm.getHandling(direction).tailLift}}):_vm._e()],1)}),1),_c('new-shipment-sidebar-group',{staticClass:"tw-mb-8",attrs:{"to":{
        name: 'NewShipmentDates'
      },"disabled":!_vm.isHandlingsCompleted}},_vm._l((['pickup', 'delivery']),function(direction){return _c('new-shipment-sidebar-group-item',{key:direction,attrs:{"name":_vm.$t(("new-shipment.titles.dates_summary." + direction))},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ui-ctk-icon',{class:[
              direction === 'pickup' ? 'tw-text-blue-500' : 'tw-text-green-500'
            ],attrs:{"name":"calendar"}})]},proxy:true}],null,true)},[(_vm.getDate(direction))?_c('new-shipment-sidebar-group-item-date',{attrs:{"direction":direction,"flexible":_vm.isFlexible(direction),"date":_vm.getDate(direction),"timeslot":_vm.getTimeslot(direction)}}):_vm._e()],1)}),1),_c('new-shipment-sidebar-group',{staticClass:"tw-hidden md:tw-flex",class:{
        'tw-mb-8': !(_vm.priceSurge && _vm.$route.name === 'NewShipmentInformations')
      }},[_c('new-shipment-sidebar-group-item',{attrs:{"name":_vm.$t('new-shipment.titles.quotation'),"to":{
          name: 'NewShipmentQuotation'
        },"disabled":!(_vm.getQuotation && _vm.isDatesCompleted)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticClass:"tw-ml-2",attrs:{"src":require("@/assets/img/icons/quotation/budget.svg"),"alt":"","width":"16"}})]},proxy:true}])},[_c('div',{staticClass:"tw-flex-1 tw-flex tw-justify-between tw-items-center tw-pr-2 tw-py-1"},[(!(_vm.getQuotation && _vm.getQuotationSelectedPrice && _vm.isDatesCompleted))?_c('div',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.quotation'))}}):_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"fs-13 tw-text-gray-700",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.quotation_summary_price_type'))}}),_c('div',{staticClass:"tw-text-sm tw-text-secondary tw-font-medium",domProps:{"textContent":_vm._s(_vm.offers[_vm.getQuotationSelectedPrice.type])}})]),(_vm.getQuotation && _vm.getQuotationSelectedPrice && _vm.isDatesCompleted)?_c('price-total',{attrs:{"price-included":("" + (_vm.$t('price_incl_tax', {
              price: _vm.priceVatIncluded
            })) + (_vm.priceSurge ? '*' : '')),"price-excluded":("" + (_vm.$t('price_excl_tax', {
              price: _vm.price
            })) + (_vm.priceSurge ? '*' : ''))}}):_vm._e()],1)])],1),(_vm.priceSurge && _vm.$route.name === 'NewShipmentInformations')?_c('p',{staticClass:"tw-text-blue-500 fs-13 tw-italic tw-mb-8 tw-mt-2",domProps:{"textContent":_vm._s(("* " + (_vm.$t(_vm.priceSurge === 'emergency'
        ? 'new-shipment.paragraphs.quotation.price_surge.emergency'
        : 'new-shipment.paragraphs.quotation.price_surge'))))}}):_vm._e(),_c('new-shipment-sidebar-group',{staticClass:"tw-hidden md:tw-flex"},[_c('new-shipment-sidebar-group-item',{attrs:{"name":_vm.$t('new-shipment.titles.informations'),"to":{
          name: 'NewShipmentInformations'
        },"disabled":!(_vm.isQuotationCompleted)},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticClass:"tw-ml-2",attrs:{"src":require("@/assets/img/icons/quotation/list.svg"),"alt":"","width":"16"}})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }