var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-informations"},[_c('h2',{staticClass:"new-shipment-informations__title tw-font-normal",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.informations'))}}),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('div',{staticClass:"new-shipment-informations__side tw-relative tw-flex tw-mb-8"},[_c('div',{staticClass:"md:tw-w-1/2"},[(!_vm.getVatNumber)?_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.quotation.vat_explanation'))}})]):_vm._e(),(!_vm.getVatNumber)?_c('ValidationProvider',{ref:"vat_number-provider",attrs:{"name":_vm.$t('app.fields.vat_number'),"rules":'required|max:255',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-8",attrs:{"id":"vat_number","name":"vat_number","type":"text","label":_vm.$t('app.labels.vat_number'),"error":invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"required":""},model:{value:(_vm.formData.vatNumber),callback:function ($$v) {_vm.$set(_vm.formData, "vatNumber", $$v)},expression:"formData.vatNumber"}})]}}],null,false,3168849080)}):_vm._e(),_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.quotation.phone_explanation'))}})]),_c('ValidationProvider',{ref:"contact_phone-provider",attrs:{"name":_vm.$t('phone'),"rules":("telephone:" + _vm.country + "|required"),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-phone-number',{staticClass:"tw-mb-8",attrs:{"id":"contact_phone","country":_vm.country,"error":invalid && validated,"hint":errors[0],"label":_vm.$t('new-shipment.labels.contact_phone'),"disabled":_vm.$wait.is('accepting quotation'),"name":"contact_phone","autocomplete":"tel-national","required":""},on:{"update:country":function($event){_vm.country=$event},"select-country":_vm.changeCountry},model:{value:(_vm.formData.contactPhone),callback:function ($$v) {_vm.$set(_vm.formData, "contactPhone", $$v)},expression:"formData.contactPhone"}})]}}])}),(_vm.hasExpirationDate)?[_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.quotation.expiration_explanation'))}})]),_c('ValidationProvider',{ref:"expires_at-provider",attrs:{"name":_vm.$t('new-shipment.fields.expiration_date'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-date-time-picker',{ref:"expires_at",staticClass:"tw-mb-4",attrs:{"id":"expires_at-input","name":"expires_at","label":_vm.$t('new-shipment.labels.expiration_date'),"error":invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"locale":_vm.$i18n.locale,"behaviour":{
                    time: {
                      nearestIfDisabled: false
                    }
                  },"color":"#287696","format":"YYYY-MM-DDTHH:mm:ss","formatted":"ddd LL LT","max-date":_vm.maxExpirationDate,"min-date":_vm.minExpirationDate,"no-button-now":"","required":""},on:{"is-shown":_vm.updateExpirationTooltipPlacement,"input":_vm.checkExpirationDate},model:{value:(_vm.formData.expirationDate),callback:function ($$v) {_vm.$set(_vm.formData, "expirationDate", $$v)},expression:"formData.expirationDate"}})]}}],null,false,778569832)}),_c('div',{staticClass:"new-shipment-informations__suggested tw-mt-2 tw-text-sm tw-pl-2"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('new-shipment.values.dates.suggested_expires_at', {
                  date: _vm.$moment(_vm.getQuotation.suggested_expires_at).format('lll')
                }))}}),_c('UiLink',{directives:[{name:"matomo",rawName:"v-matomo",value:({
                  click: {
                    category: 'Quotation',
                    action: 'Choose Default Expiration Delay'
                  }
                }),expression:"{\n                  click: {\n                    category: 'Quotation',\n                    action: 'Choose Default Expiration Delay'\n                  }\n                }"}],attrs:{"href":"#","role":"button","standalone":""},on:{"click":function($event){$event.preventDefault();return _vm.setSuggestedExpiresAt.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.buttons.use'))+" ")])],1),(_vm.expirationTip)?_c('div',{staticClass:"tw-p-2 new-shipment-informations__expiration-tip tw-rounded tw-mb-4 md:tw-hidden"},[_c('new-shipment-informations-expiration-tip',{on:{"close":function($event){_vm.expirationTip = false}}})],1):_vm._e(),_c('b-popover',{ref:"expires-at-popover",attrs:{"show":_vm.expirationTip,"placement":_vm.expirationTipPlacement,"target":"expires_at-input-input","custom-class":"new-shipment-informations__tip-popover tw-hidden md:tw-block","triggers":"manual","container":"quotation-dialog"},on:{"update:show":function($event){_vm.expirationTip=$event}}},[_c('new-shipment-informations-expiration-tip',{on:{"close":function($event){_vm.expirationTip = false}}})],1)]:_vm._e()],2)]),_c('div',[_c('h3',{staticClass:"tw-font-normal tw-text-base"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.your_references'))}}),_c('span',{staticClass:"tw-text-xs tw-text-gray-700 tw-ml-1",domProps:{"textContent":_vm._s(("(" + (_vm.$options.filters.capitalize(_vm.$t('optional'))) + ")"))}})]),_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.quotation.reference_explanation'))}})]),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row"},[_c('div',{staticClass:"new-shipment-informations__references__side tw-relative md:tw-w-1/2 md:tw-mr-5"},[_c('ValidationProvider',{ref:"pickup_reference-provider",attrs:{"name":_vm.$t('new-shipment.fields.pickup_reference'),"rules":'max:50',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"pickup_reference","name":"pickup_reference","type":"text","label":_vm.$t('new-shipment.labels.pickup_reference'),"error":invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation')},model:{value:(_vm.formData.pickupReference),callback:function ($$v) {_vm.$set(_vm.formData, "pickupReference", $$v)},expression:"formData.pickupReference"}})]}}])}),_c('ValidationProvider',{ref:"delivery_reference-provider",attrs:{"name":_vm.$t('new-shipment.fields.delivery_reference'),"rules":'max:50',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2 md:tw-mb-0",attrs:{"id":"delivery_reference","name":"delivery_reference","type":"text","label":_vm.$t('new-shipment.labels.delivery_reference'),"error":invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation')},model:{value:(_vm.formData.deliveryReference),callback:function ($$v) {_vm.$set(_vm.formData, "deliveryReference", $$v)},expression:"formData.deliveryReference"}})]}}])})],1),_c('div',{staticClass:"md:tw-w-1/2"},[_c('ValidationProvider',{ref:"shipper_reference-provider",attrs:{"name":_vm.$t('new-shipment.fields.shipper_reference'),"rules":'max:50',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"shipper_reference","name":"shipper_reference","type":"text","label":_vm.$t('new-shipment.labels.shipper_reference'),"error":invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation')},model:{value:(_vm.formData.shipperReference),callback:function ($$v) {_vm.$set(_vm.formData, "shipperReference", $$v)},expression:"formData.shipperReference"}})]}}])})],1)])],1),_c('div',{staticClass:"tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6"},[_c('div',{staticClass:"tw-mt-4 2sm:tw-mt-0"},[_c('ui-button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
              click: {
                category: 'Quotations',
                action: 'Clicked Back'
              }
            }),expression:"{\n              click: {\n                category: 'Quotations',\n                action: 'Clicked Back'\n              }\n            }"}],staticClass:"tw-w-full 2sm:tw-w-auto",attrs:{"to":{
              name: 'NewShipmentQuotation'
            },"variant":"link"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"keyboard_arrow_left"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('back')))+" ")])],1),_c('ui-button',{attrs:{"loading":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.buttons.request_shipment'))+" ")])],1)])]),(_vm.isSelectedPriceCreditCard || _vm.isSelectedPriceCCAuthorization)?_c('new-shipment-acceptation-payment-dialog',{attrs:{"confirmation":_vm.dialogs.acceptation.confirmation,"error":_vm.dialogs.acceptation.error,"shipment":_vm.dialogs.acceptation.shipment},on:{"retry":_vm.retry,"close":_vm.close},model:{value:(_vm.dialogs.acceptation.visible),callback:function ($$v) {_vm.$set(_vm.dialogs.acceptation, "visible", $$v)},expression:"dialogs.acceptation.visible"}}):_c('new-shipment-acceptation-booking-dialog',{attrs:{"confirmation":_vm.dialogs.acceptation.confirmation,"error":_vm.dialogs.acceptation.error,"shipment":_vm.dialogs.acceptation.shipment},on:{"retry":_vm.retry,"close":_vm.close},model:{value:(_vm.dialogs.acceptation.visible),callback:function ($$v) {_vm.$set(_vm.dialogs.acceptation, "visible", $$v)},expression:"dialogs.acceptation.visible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }