<template>
  <div class="new-shipment">
    <div class="ctk-container">
      <h1 class="new-shipment__title tw-font-medium tw-text-2xl">
        {{ $t('new-shipment.titles.main') }}
      </h1>
      <new-shipment-mobile-navigation
        :active="activeStep"
        class="mb-3"
      />
      <div class="new-shipment__layout tw-flex">
        <div
          class="tw-flex tw-flex-1 tw-flex-col new-shipment__layout__block new-shipment__layout__main-container tw-rounded tw-bg-white mr-3"
          data-test="new-shipment-container"
        >
          <router-view
            :key="$route.fullPath"
          />
          <div
            class="new-shipment__layout-arrow"
            :style="{
              transform: `translateY(${arrowHeight}px) rotate(45deg)`
            }"
          />
        </div>
        <new-shipment-sidebar
          class="new-shipment__layout__block new-shipment__layout__sidebar tw-rounded tw-bg-white"
          @update-arrow="updateArrow"
        />
      </div>
      <new-shipment-axa
        class="tw-rounded mt-3 p-4"
      />

      <!-- Dialogs -->
      <new-shipment-loss-of-entry-dialog
        ref="loss-of-entry"
        v-model="dialogs.lossOfEntry"
        data-test="loss-of-entry"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import store from '@/store'
  import { EventBus } from '@/services/EventBus'
  import Hotjar from '@/plugins/VueHotjar'

  import NewShipmentMobileNavigation from './components/NewShipmentMobileNavigation'
  import NewShipmentSidebar from './components/NewShipmentSidebar'
  import NewShipmentLossOfEntryDialog from './components/NewShipmentLossOfEntryDialog'

  async function beforeRoute (to, from, next) {
    if (to.name === 'NewShipment') {
      /**
       * Handle the redirection to either the address view
       * or the template view according ot the metrics values.
       * This creates a mini delay when fetching the metrics infos.
       */
      await store.dispatch('shipments/retrieveShipmentsMetrics')

      /**
       * Triggers a getMe to potentially retrieve the new is_onboarded status
       * of the shipper. This is made async to avoid blocking the form loading for
       * not much.
       */
      store.dispatch('auth/getMe')

      const metrics = store.getters['shipments/getShipmentsMetrics']
      if (metrics && metrics.counts && !!metrics.counts.total && metrics.counts.total >= 1) {
        next({
          name: 'NewShipmentTemplate'
        })
        return false
      }

      /**
       * In the case where we're already in the address route, ignore
       */
      if (from.name === 'NewShipmentAddress' && from.params.direction === 'pickup') {
        next(false)
        return false
      }

      next({
        name: 'NewShipmentAddress',
        params: {
          direction: 'pickup'
        }
      })
      return false
    } else {
      next()
      return false
    }
  }

  async function lossOfEntryDialog (vm) {
    vm.dialogs.lossOfEntry = true

    Hotjar.tag('Leave The Form')

    if (vm.$matomo) {
      vm.$matomo.trackEvent('Quotations', 'Exit Form Message')
    }

    return new Promise(resolve => {
      /**
       * When the user clicks on either leave / stay buttons in the
       * dialog.
       */
      vm.$refs['loss-of-entry'].$on('confirm', (confirm) => {
        if (confirm) {
          vm.dialogs.lossOfEntry = false
        }

        if (vm.$matomo) {
          vm.$matomo.trackEvent('Quotations', confirm ? 'Exit Form Continue' : 'Exit Form Confirm')
        }

        resolve(!confirm)
      })
    })
  }

  /**
   * Used as a flag to know if it's the first time the beforeLeave hook is called.
   * Used this since the beforeLeave is called twice in the case of a redirect.
   * @var firstBeforeLeave
   */
  let firstBeforeLeave = true

  /**
   * @module view - NewShipment
   */
  export default {
    name: 'NewShipment',
    metaInfo () {
      return {
        title: this.$t('new-shipment.titles.main')
      }
    },
    components: {
      NewShipmentSidebar,
      NewShipmentMobileNavigation,
      NewShipmentLossOfEntryDialog
    },
    data () {
      return {
        arrowHeight: 0,
        dialogs: {
          lossOfEntry: false
        }
      }
    },
    async beforeRouteEnter (to, from, next) {
      firstBeforeLeave = true
      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'Shipments'
        })
        return
      }

      const routeRes = await beforeRoute(to, from, next)
      if (!routeRes) return false
    },
    async beforeRouteUpdate (to, from, next) {
      if (to.name === 'NewShipment') {
        const routeRes = await beforeRoute(to, from, next)
        if (!routeRes) return false
      }

      try {
        const container = document.querySelector('.main-container')
        if (container) {
          container.scrollTo(0, 0)
          window.scrollTo(0, 0)
        }
      } catch (e) {
        console.error('Could not scroll to the top of the page', e)
      }
      next()
    },
    async beforeRouteLeave (to, from, next) {
      const { 'ignore-loss-of-entry': ignoreLossOfEntry } = to.query
      const ignoredRoutes = ['SignIn', 'Auth']

      const hasFormChange = !ignoreLossOfEntry && !ignoredRoutes.includes(to.name) && store.getters['shipments/new-shipment/hasChanged'] && firstBeforeLeave
      const isConfirmation = store.getters['shipments/new-shipment/isConfirmationStep']

      if (hasFormChange && !isConfirmation) {
        const confirmation = await lossOfEntryDialog(this)
        if (confirmation) {
          firstBeforeLeave = false
          next()
          store.dispatch('shipments/new-shipment/resetForm')
        } else {
          next(false)
        }
      } else {
        next()
        store.dispatch('shipments/new-shipment/resetForm')
      }
    },
    mounted () {
      this.setAppReady(true)

      EventBus.$on('dialogs:address-not-found', (direction) => {
        this.dialogs.addressNotFound = {
          direction,
          visible: true
        }
      })
    },
    methods: {
      ...mapActions(['setAppReady']),
      updateArrow ({ top, height }) {
        const {
          top: arrowTop
        } = document.querySelector('.new-shipment__layout__main-container').getBoundingClientRect()
        const arrowHeight = 20

        this.arrowHeight = (top - arrowTop) + (height / 2) - (arrowHeight / 2)
      }
    },
    computed: {
      activeStep () {
        const steps = ['NewShipmentAddress', 'NewShipmentGoods', 'NewShipmentHandling', 'NewShipmentDates']

        /**
         * For the mobile navigation, if we're in the last steps, ensure the
         * active item is the "dates" one.
         */
        const lastSteps = ['NewShipmentQuotation', 'NewShipmentInformations']
        if (lastSteps.includes(this.$route.name)) return 3

        return steps.findIndex(step => step === this.$route.name)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment {
    overflow-y: auto !important;

    .ctk-container {
      padding-bottom: 32px;
    }

    &__title {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .new-shipment-mobile-navigation {
      display: none;

      @media only screen and (max-width: $breakpoint-tablet) {
        display: flex;
      }
    }

    &__layout {
      &__block {
        border: 1px solid $divider;
      }

      &__main-container {
        position: relative;
        width: 66.66%;
        padding: 30px 40px;
      }

      &__sidebar {
        width: 33.33%;
        align-self: flex-start;
      }

      &-arrow {
        position: absolute;
        right: -8px;
        top: 0;
        width: 14px;
        height: 14px;
        background-color: white;
        transform: rotate(45deg);
        transition: transform 200ms ease-in-out;
        border-top: 1px solid $divider;
        border-right: 1px solid $divider;

        @media only screen and (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      padding: 0 16px 32px 16px;

      &__layout__main-container {
        padding: 16px;
        margin-bottom: 16px;
        flex: 0;
      }

      &__layout {
        flex-direction: column;

        &__main-container,
        &__sidebar {
          width: 100%;
          margin-right: 0;
        }
      }

      &-axa {
        padding: 16px !important;
      }

      .new-shipment__layout__sidebar {
        display: none;
      }
    }
  }

</style>
