<template>
  <div class="new-shipment-template-list tw-px-4">
    <div class="ctk-container tw-pb-8">
      <div>
        <UiLink
          :to="{ name: 'NewShipmentTemplate' }"
          class="tw-mt-2"
          standalone
          data-test="back"
        >
          <template #left-icon>
            <UiMaterialIcon
              class="tw-mr-2 tw-text-xl"
              name="keyboard_arrow_left"
            />
          </template>

          {{ $t('back') | capitalize }}
        </UiLink>
        <h1
          class="new-shipment-template-list__title tw-font-medium tw-text-2xl tw-mt-0 tw-mb-5"
          v-text="$t('new-shipment.titles.pick_template')"
          data-test="title"
        />
      </div>

      <div class="new-shipment-template-list__layout tw-relative tw-flex tw-flex-col tw-py-5 tw-px-4 md:tw-px-8 tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white md:tw-py-8">
        <h2
          v-if="!$wait.is('fetching shipments')"
          class="new-shipment-template-list__layout__title tw-relative tw-text-xl tw-font-normal tw-mb-8"
          v-text="$tc('new-shipment.titles.templates', getFetchedShipments.length, {
            count: getFetchedShipments.length
          })"
          data-test="subtitle"
        />
        <ctk-skeleton-block
          v-else
          width="200px"
          height="35px"
          level="2"
          loading
          class="tw-mb-5"
        />

        <div class="tw-flex tw-flex-col">
          <template
            v-if="$wait.is('fetching shipments')"
          >
            <ctk-skeleton-view
              depth="1"
              class="tw-flex tw-flex-col"
            >
              <ctk-skeleton-block
                width="500px"
                height="55px"
                level="3"
                loading
                class="tw-mb-3"
              />
              <ctk-skeleton-block
                width="400px"
                height="55px"
                level="3"
                loading
                class="tw-mb-3"
              />
              <ctk-skeleton-block
                width="550px"
                height="55px"
                level="3"
                loading
                class="tw-mb-3"
              />
            </ctk-skeleton-view>
          </template>
          <template
            v-else
          >
            <new-shipment-template-list-item
              v-for="shipment in getFetchedShipments"
              :key="shipment.uuid"
              :shipment="shipment"
              class="tw-mb-4"
              @click="select(shipment)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'

  import useRouter from '@/composables/useRouter'
  import useDuplicateShipment from '@/composables/shipments/useDuplicateShipment'

  import CtkSkeletonView from '@/components/CtkSkeletonView/index.vue'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'
  import NewShipmentTemplateListItem from './components/NewShipmentTemplateListItem/index.vue'

  /**
   * @module component - NewShipmentTemplateList
   */
  export default defineComponent({
    name: 'NewShipmentTemplateList',
    components: {
      CtkSkeletonView,
      CtkSkeletonBlock,
      NewShipmentTemplateListItem
    },
    metaInfo () {
      return {
        title: this.$t('new-shipment.titles.main')
      }
    },
    // @ts-ignore
    async beforeRouteEnter (to, from, next) {
      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'Shipments'
        })
        return
      }

      store.dispatch('setAppReady', true)
      next()
    },
    setup () {
      const router = useRouter()

      const select = (shipment) => {
        useDuplicateShipment(shipment)

        router.value.push({
          name: 'NewShipmentAddress',
          params: {
            direction: 'pickup'
          }
        })
          .catch(() => {})
      }

      return {
        select
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getFetchedShipments'
      ])
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'retrieveShipments'
      ])
    },
    mounted () {
      this.$wait.start('fetching shipments')
      this.retrieveShipments()
        .finally(() => {
          this.$wait.end('fetching shipments')
        })
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-template-list {
  overflow-y: auto !important;
}
.new-shipment-template-list__layout {
  padding-bottom: 270px;
}
.new-shipment-template-list__layout__title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 200px;
  height: 1px;
  background-color: $divider;
}
.new-shipment-template-list__layout::after {
  content: '';
  position: absolute;
  bottom: 50px;
  right: 0;
  background-image: url('~@/assets/img/illustrations/shipments.svg');
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  opacity: 0.8;
  height: 220px;
  width: 100%;
}
@media (min-width: 770px) {
  .new-shipment-template-list .new-shipment-template-list__layout::after {
    width: 660px;
  }
}
</style>
