<template>
  <ctk-aside-dialog
    v-model="dialogValue"
    id="acceptation-dialog"
    modal-class="new-shipment-acceptation-dialog"
    :title="title"
    :has-illustration="hasIllustration"
  >
    <template
      v-if="error || (shipment && confirmation && !error)"
      #body
    >
      <template
        v-if="error"
      >
        <button
          :title="$t('close') | capitalize"
          class="new-shipment-acceptation-dialog__close"
          type="button"
          data-test="close"
          @click="close"
        >
          <ui-ctk-icon
            name="close"
            data-test="icon"
          />
        </button>
        <invoices-pay-dialog-payment-status
          v-if="error"
          :title="$t('app.titles.pay.declined')"
          :button-title="$t('app.buttons.retry_payment')"
          data-test="error"
          @click="$emit('retry')"
        >
          <p
            v-text="$t('app.paragraphs.pay.declined')"
            data-test="content"
          />
        </invoices-pay-dialog-payment-status>
      </template>

      <new-shipment-acceptation-dialog-confirmation
        v-else-if="shipment && confirmation && !error"
        :shipment="shipment"
      />
    </template>

    <template
      #aside
    >
      <new-shipment-acceptation-dialog-summary
        :shipment="formattedShipment"
        :has-price="hasPrice"
        data-test="summary"
      />

      <p
        v-if="priceSurge"
        data-test="price-surge-paragraph"
        :class="{
          'tw-mt-10': priceSurge === 'emergency',
          'tw-mt-2 tw-w-1/2': priceSurge !== 'emergency'
        }"
        class="tw-text-blue-500 fs-13 tw-italic tw-mb-8"
        v-text="`* ${$t(priceSurge === 'emergency'
          ? 'new-shipment.paragraphs.quotation.price_surge.emergency'
          : 'new-shipment.paragraphs.quotation.price_surge')}`"
      />

      <slot name="aside" />
    </template>

    <!-- Workaround to pass the default slot from the parent to the child component -->
    <template
      #default
    >
      <slot />
    </template>
  </ctk-aside-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import { offers } from '@/../config/chronoinfos'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkAsideDialog from '@/components/CtkAsideDialog/index.vue'
  import InvoicesPayDialogPaymentStatus from '@/views/Shippers/Billing/components/InvoicesPayDialog/_subs/InvoicesPayDialogPaymentStatus/index.vue'
  import NewShipmentAcceptationDialogConfirmation from './_subs/NewShipmentAcceptationDialogConfirmation/index.vue'
  import NewShipmentAcceptationDialogSummary from './_subs/NewShipmentAcceptationDialogSummary/index.vue'

  /**
   * @module component - NewShipmentAcceptationDialog
   * @param {string} title
   * @param {boolean} hasPrice
   * @param {boolean} hasIllustration
   * @param {boolean} [confirmation=false] - Show the confirmation step
   * @param {boolean} [error=false] - Show the error step
   * @param {object} [shipment=null] - The new shipment
   * @emits close
   */
  export default defineComponent({
    name: 'NewShipmentAcceptationDialog',
    props: {
      title: {
        type: String,
        required: true
      },
      hasPrice: {
        type: Boolean,
        required: true
      },
      hasIllustration: {
        type: Boolean,
        default: true
      },
      value: {
        type: Boolean,
        default: true
      },
      confirmation: {
        type: Boolean,
        default: false
      },
      error: {
        type: Boolean,
        default: false
      },
      shipment: {
        type: Object,
        default: null
      }
    },
    components: {
      CtkAsideDialog,
      InvoicesPayDialogPaymentStatus,
      NewShipmentAcceptationDialogConfirmation,
      NewShipmentAcceptationDialogSummary
    },
    setup (props, { emit }) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      function close () {
        dialogValue.value = false
        emit('close')
      }

      return {
        dialogValue,
        close
      }
    },
    data () {
      return {
        offers,
        formData: {
          tos: false,
          ccNetwork: false
        }
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getQuotation',
        'getPickupAddress',
        'getPickupDate',
        'isPickupFlexible',
        'getPickupTimeslot',
        'getDeliveryTimeslot',
        'getDeliveryAddress',
        'getDeliveryDate',
        'isDeliveryFlexible',
        'getQuotationSelectedPrice',
        'isSelectedPriceNetwork',
        'isSelectedPriceCreditCard'
      ]),
      /**
       * @function hasMissingCheckboxes
       * @returns {boolean}
       */
      hasMissingCheckboxes () {
        const hasCCNetworkCheckbox = this.isSelectedPriceNetwork && this.isSelectedPriceCreditCard
        const { tos, ccNetwork } = this.formData

        if (!tos) return true
        if (hasCCNetworkCheckbox && !ccNetwork) return true
        return false
      },
      /**
       * Returns either the Chronotruck price or the custom price, if available
       * @function getPrice
       * @returns {object}
       */
      getPrice () {
        if (!this.getQuotation) return
        const ALLOWED_PRICE_TYPES = ['chronotruck', 'custom']
        return this.getQuotation.prices
          .find(price => ALLOWED_PRICE_TYPES.includes(price.type))
      },
      /**
       * Returns the quotation price surge
       * @function priceSurge
       * @returns {string|null}
       */
      priceSurge () {
        const price = this.getPrice
        return price && price.price_surge
      },
      /**
       * Returns a re-build shipment object with the existing data
       * from the shipment form.
       * @function formattedShipment
       * @returns {Object} shipment
       */
      formattedShipment () {
        const pickup = {
          address: this.getPickupAddress,
          time_slot: {
            date: this.getPickupDate,
            flexible: this.isPickupFlexible,
            start_time: this.$moment().startOf('day').seconds(this.getPickupTimeslot[0]).format('HH:mm')
          }
        }

        const delivery = {
          address: this.getDeliveryAddress,
          time_slot: {
            date: this.getDeliveryDate,
            flexible: this.isDeliveryFlexible,
            start_time: this.$moment().startOf('day').seconds(this.getDeliveryTimeslot[0]).format('HH:mm')
          }
        }

        return {
          pickup,
          delivery
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-acceptation-dialog__main__checkbox {
  color: $secondary-text;
}
.new-shipment-acceptation-dialog__close {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  border: none;
  background: transparent;
  appearance: none;
  z-index: 1;
}
</style>
