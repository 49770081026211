<template>
  <new-shipment-types-item
    :title="title"
    :active="active"
    class="new-shipment-goods-types-item"
  >
    <template
      v-if="icon"
    >
      <ui-ctk-icon
        :name="icon"
        class="new-shipment-goods-types-item__icon"
        data-test="icon"
      />
    </template>
    <div
      class="new-shipment-goods-types-item__title tw-font-medium tw-text-sm"
      v-text="title"
      data-test="title"
    />
    <div
      v-if="subtitle"
      class="new-shipment-goods-types-item__subtitle tw-text-xs"
      v-text="subtitle"
      data-test="subtitle"
    />
  </new-shipment-types-item>
</template>

<script>
  import NewShipmentTypesItem from '@/views/Shippers/NewShipment/components/NewShipmentTypesItem/index.vue'

  /**
   * @module component - NewShipmentGoodsTypesItem
   * @param {string} [active=false]
   * @param {string} icon
   * @param {string} title
   * @param {string} [subtitle=null]
   */
  export default {
    name: 'NewShipmentGoodsTypesItem',
    components: {
      NewShipmentTypesItem
    },
    props: {
      active: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>
.new-shipment-goods-types-item__icon {
  font-size: 50px;
}
.new-shipment-goods-types-item:not(.active) .new-shipment-goods-types-item__title {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
</style>
