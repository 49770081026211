<template>
  <div class="new-shipment-quotation-price-lines">
    <div
      :class="{
        open: isOpen
      }"
      class="new-shipment-quotation-price-lines__header tw-border-0 tw-border-b tw-border-solid tw-flex tw-justify-end tw-mb-2"
      data-test="header"
    >
      <ui-button
        :class="{
          open: isOpen
        }"
        type="button"
        variant="link"
        size="sm"
        class="new-shipment-quotation-price-lines__header__button"
        data-test="button"
        @click="isOpen = !isOpen"
      >
        <span
          data-test="title"
          v-text="$t('new-shipment.labels.quotation_detail')"
        />

        <template #right-icon>
          <ui-material-icon
            name="arrow_drop_down"
            class="!tw-text-lg tw-transition-transform"
            data-test="icon"
          />
        </template>
      </ui-button>
    </div>
    <b-collapse
      :id="'prices'"
      v-model="isOpen"
      role="tabpanel"
    >
      <slot />
    </b-collapse>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - NewShipmentQuotationPriceLines
   */
  export default defineComponent({
    name: 'NewShipmentQuotationPriceLines',
    props: {
      open: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: isOpen } = useModelGetterSetter(props, 'open')

      return {
        isOpen
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-quotation-price-lines__header.open {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(40, 118, 150, var(--tw-border-opacity));
}
.new-shipment-quotation-price-lines__header:not(.open) {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.new-shipment-quotation-price-lines__header__button.open .material-icons {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  --tw-rotate: 180deg;
}
</style>
