<template>
  <div class="new-shipment-informations">
    <h2
      class="new-shipment-informations__title tw-font-normal"
      data-test="title"
      v-text="$t('new-shipment.titles.informations')"
    />

    <ValidationObserver
      ref="observer"
      slim
    >
      <form
        @submit.prevent="submitted"
      >
        <div
          class="new-shipment-informations__side tw-relative tw-flex tw-mb-8"
        >
          <div
            class="md:tw-w-1/2"
          >
            <!-- VAT number -->
            <new-shipment-alert
              v-if="!getVatNumber"
              data-test="vat-explanation"
            >
              <p
                class="tw-mb-0"
                v-text="$t('new-shipment.paragraphs.quotation.vat_explanation')"
              />
            </new-shipment-alert>

            <ValidationProvider
              v-if="!getVatNumber"
              ref="vat_number-provider"
              :name="$t('app.fields.vat_number')"
              :rules="'required|max:255'"
              slim
              data-test="vat-number-provider"
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="vat_number"
                  v-model="formData.vatNumber"
                  name="vat_number"
                  type="text"
                  :label="$t('app.labels.vat_number')"
                  :error="invalid && validated"
                  :hint="errors[0]"
                  :loader="$wait.is('accepting quotation')"
                  :disabled="$wait.is('accepting quotation')"
                  data-test="vat_number"
                  class="tw-mb-8"
                  required
                />
              </template>
            </ValidationProvider>

            <!-- Contact phone -->
            <new-shipment-alert
              data-test="phone-explanation"
            >
              <p
                class="tw-mb-0"
                v-text="$t('new-shipment.paragraphs.quotation.phone_explanation')"
              />
            </new-shipment-alert>

            <ValidationProvider
              ref="contact_phone-provider"
              :name="$t('phone')"
              :rules="`telephone:${country}|required`"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-phone-number
                  id="contact_phone"
                  v-model="formData.contactPhone"
                  :country.sync="country"
                  :error="invalid && validated"
                  :hint="errors[0]"
                  :label="$t('new-shipment.labels.contact_phone')"
                  :disabled="$wait.is('accepting quotation')"
                  name="contact_phone"
                  autocomplete="tel-national"
                  required
                  class="tw-mb-8"
                  data-test="contact_phone"
                  @select-country="changeCountry"
                />
              </template>
            </ValidationProvider>

            <template
              v-if="hasExpirationDate"
            >
              <new-shipment-alert
                data-test="expiration-explanation"
              >
                <p
                  class="tw-mb-0"
                  v-text="$t('new-shipment.paragraphs.quotation.expiration_explanation')"
                />
              </new-shipment-alert>

              <!-- TODO: Use our custom ctkInput instead of the default one from the datepicker, that includes the required rule -->
              <ValidationProvider
                ref="expires_at-provider"
                :name="$t('new-shipment.fields.expiration_date')"
                :rules="'required'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-date-time-picker
                    ref="expires_at"
                    id="expires_at-input"
                    v-model="formData.expirationDate"
                    name="expires_at"
                    :label="$t('new-shipment.labels.expiration_date')"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    :loader="$wait.is('accepting quotation')"
                    :disabled="$wait.is('accepting quotation')"
                    :locale="$i18n.locale"
                    :behaviour="{
                      time: {
                        nearestIfDisabled: false
                      }
                    }"
                    color="#287696"
                    format="YYYY-MM-DDTHH:mm:ss"
                    formatted="ddd LL LT"
                    :max-date="maxExpirationDate"
                    :min-date="minExpirationDate"
                    no-button-now
                    data-test="expires_at"
                    class="tw-mb-4"
                    required
                    @is-shown="updateExpirationTooltipPlacement"
                    @input="checkExpirationDate"
                  />
                </template>
              </ValidationProvider>

              <div
                class="new-shipment-informations__suggested tw-mt-2 tw-text-sm tw-pl-2"
                data-test="suggested-expires-at"
              >
                <div
                  v-text="$t('new-shipment.values.dates.suggested_expires_at', {
                    date: $moment(getQuotation.suggested_expires_at).format('lll')
                  })"
                  data-test="suggested-expires-at-date"
                />
                <UiLink
                  href="#"
                  role="button"
                  standalone
                  data-test="suggested-expires-at-button"
                  v-matomo="{
                    click: {
                      category: 'Quotation',
                      action: 'Choose Default Expiration Delay'
                    }
                  }"
                  @click.prevent="setSuggestedExpiresAt"
                >
                  {{ $t('new-shipment.buttons.use') }}
                </UiLink>
              </div>

              <div
                v-if="expirationTip"
                class="tw-p-2 new-shipment-informations__expiration-tip tw-rounded tw-mb-4 md:tw-hidden"
                data-test="expiration-tip"
              >
                <new-shipment-informations-expiration-tip
                  @close="expirationTip = false"
                />
              </div>

              <b-popover
                :show.sync="expirationTip"
                :placement="expirationTipPlacement"
                ref="expires-at-popover"
                target="expires_at-input-input"
                custom-class="new-shipment-informations__tip-popover tw-hidden md:tw-block"
                triggers="manual"
                container="quotation-dialog"
              >
                <new-shipment-informations-expiration-tip
                  @close="expirationTip = false"
                />
              </b-popover>
            </template>
          </div>
        </div>

        <div
          data-test="references"
        >
          <h3
            data-test="title"
            class="tw-font-normal tw-text-base"
          >
            <span
              v-text="$t('new-shipment.titles.your_references')"
            />
            <span
              v-text="`(${$options.filters.capitalize($t('optional'))})`"
              class="tw-text-xs tw-text-gray-700 tw-ml-1"
              data-test="optional"
            />
          </h3>
          <new-shipment-alert
            data-test="reference-explanation"
          >
            <p
              class="tw-mb-0"
              v-text="$t('new-shipment.paragraphs.quotation.reference_explanation')"
            />
          </new-shipment-alert>

          <div class="tw-flex tw-flex-col md:tw-flex-row">
            <div class="new-shipment-informations__references__side tw-relative md:tw-w-1/2 md:tw-mr-5">
              <ValidationProvider
                ref="pickup_reference-provider"
                :name="$t('new-shipment.fields.pickup_reference')"
                :rules="'max:50'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="pickup_reference"
                    v-model="formData.pickupReference"
                    name="pickup_reference"
                    type="text"
                    :label="$t('new-shipment.labels.pickup_reference')"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    :loader="$wait.is('accepting quotation')"
                    :disabled="$wait.is('accepting quotation')"
                    data-test="pickup_reference"
                    class="tw-mb-2"
                  />
                </template>
              </ValidationProvider>
              <ValidationProvider
                ref="delivery_reference-provider"
                :name="$t('new-shipment.fields.delivery_reference')"
                :rules="'max:50'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="delivery_reference"
                    v-model="formData.deliveryReference"
                    name="delivery_reference"
                    type="text"
                    :label="$t('new-shipment.labels.delivery_reference')"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    :loader="$wait.is('accepting quotation')"
                    :disabled="$wait.is('accepting quotation')"
                    data-test="delivery_reference"
                    class="tw-mb-2 md:tw-mb-0"
                  />
                </template>
              </ValidationProvider>
            </div>
            <div class="md:tw-w-1/2">
              <ValidationProvider
                ref="shipper_reference-provider"
                :name="$t('new-shipment.fields.shipper_reference')"
                :rules="'max:50'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="shipper_reference"
                    v-model="formData.shipperReference"
                    name="shipper_reference"
                    type="text"
                    :label="$t('new-shipment.labels.shipper_reference')"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    :loader="$wait.is('accepting quotation')"
                    :disabled="$wait.is('accepting quotation')"
                    data-test="shipper_reference"
                    class="tw-mb-2"
                  />
                </template>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div
          class="tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6"
          data-test="buttons"
        >
          <div
            class="tw-mt-4 2sm:tw-mt-0"
          >
            <ui-button
              :to="{
                name: 'NewShipmentQuotation'
              }"
              v-matomo="{
                click: {
                  category: 'Quotations',
                  action: 'Clicked Back'
                }
              }"
              variant="link"
              class="tw-w-full 2sm:tw-w-auto"
              data-test="back"
            >
              <template #left-icon>
                <ui-material-icon
                  name="keyboard_arrow_left"
                />
              </template>

              {{ $t('back') | capitalize }}
            </ui-button>
          </div>
          <ui-button
            :loading="$wait.is('accepting quotation')"
            :disabled="$wait.is('accepting quotation')"
            data-test="save-button"
            variant="primary"

            type="submit"
          >
            {{ $t('new-shipment.buttons.request_shipment') }}
          </ui-button>
        </div>
      </form>
    </ValidationObserver>

    <!-- Dialogs -->
    <new-shipment-acceptation-payment-dialog
      v-if="isSelectedPriceCreditCard || isSelectedPriceCCAuthorization"
      v-model="dialogs.acceptation.visible"
      :confirmation="dialogs.acceptation.confirmation"
      :error="dialogs.acceptation.error"
      :shipment="dialogs.acceptation.shipment"
      data-test="acceptation-dialog"
      @retry="retry"
      @close="close"
    />
    <new-shipment-acceptation-booking-dialog
      v-else
      v-model="dialogs.acceptation.visible"
      :confirmation="dialogs.acceptation.confirmation"
      :error="dialogs.acceptation.error"
      :shipment="dialogs.acceptation.shipment"
      data-test="acceptation-dialog"
      @retry="retry"
      @close="close"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'
  import { mapActions, mapGetters } from 'vuex'
  import * as Sentry from '@sentry/browser'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

  import useStripe from '@/composables/useStripe'

  import store from '@/store'
  import Hotjar from '@/plugins/VueHotjar'
  import { showToaster } from '@/services/Toaster'
  import { QuotationPrice, Company, Shipment } from '@/resources'
  import handlePropertyPathViolations from '@/resources/handlers/violations'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'

  import NewShipmentAlert from '@/views/Shippers/NewShipment/components/NewShipmentAlert/index.vue'
  import NewShipmentAcceptationBookingDialog from './components/NewShipmentAcceptationBookingDialog/index.vue'
  import NewShipmentAcceptationPaymentDialog from './components/NewShipmentAcceptationPaymentDialog/index.vue'
  import NewShipmentInformationsExpirationTip from './components/NewShipmentInformationsExpirationTip/index.vue'

  /**
   * @module view - NewShipmentInformations
   */
  export default defineComponent({
    name: 'NewShipmentInformations',
    components: {
      CtkDateTimePicker,
      CtkInputText,
      NewShipmentInformationsExpirationTip,
      NewShipmentAcceptationBookingDialog,
      NewShipmentAcceptationPaymentDialog,
      CtkPhoneNumber,
      NewShipmentAlert
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Check if the user has made the quotation
       */
      if (!store.getters['shipments/new-shipment/isQuotationCompleted']) {
        next({
          name: 'NewShipmentQuotation'
        })
        return false
      }

      next()
    },
    async mounted () {
      this.setDefaultValues()
    },
    data () {
      return {
        dialogs: {
          acceptation: {
            visible: false,
            confirmation: false,
            error: false,
            shipment: null
          }
        },
        formData: {
          contactPhone: null,
          expirationDate: null,
          pickupReference: null,
          deliveryReference: null,
          shipperReference: null,
          vatNumber: null,
          saveCard: false
        },
        acceptationDialog: false,
        expirationTipVisible: false,
        expirationTipPlacement: 'bottom',
        initialExpirationDate: false,
        country: null
      }
    },
    setup () {
      const { stripe } = useStripe()

      return {
        stripe
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid',
        'getUserInfos',
        'getVatNumber',
        'getRedirectUrl'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getPhoneNumber',
        'getQuotation',
        'getQuotationSelectedPrice',
        'getDeliveryDate',
        'getDeliveryTimeslot',
        'isSelectedPriceNetwork',
        'isSelectedPriceCreditCard',
        'isSelectedPriceCCAuthorization',
        'getCustomPrice'
      ]),
      /**
       * Returns true if the expiration date should be shown according
       * to the price type selected.
       * @function hasExpirationDate
       * @returns {boolean}
       */
      hasExpirationDate () {
        const selectedPrice = this.getQuotationSelectedPrice
        return selectedPrice && selectedPrice.type !== 'pallet_network'
      },
      maxExpirationDate () {
        if (!this.getDeliveryDate || !this.getDeliveryTimeslot) return false

        const endTime = this.$moment().startOf('day').minutes(this.getDeliveryTimeslot[1]).format('HH:mm')
        const date = this.$moment(this.getDeliveryDate).format('YYYY-MM-DD')
        return this.$moment(`${date} ${endTime}`).subtract(15, 'minutes').format()
      },
      minExpirationDate () {
        return this.$moment().format()
      },
      expirationTip: {
        get () {
          return this.expirationTipVisible
        },
        async set (v) {
          this.updateExpirationTooltipPlacement()
          this.expirationTipVisible = v
        }
      }
    },
    methods: {
      ...mapActions('auth', [
        'retrieveCompany'
      ]),
      ...mapActions('shipments/new-shipment', [
        'setPhoneNumber',
        'setExpirationDate',
        'setShipperReference',
        'setHandlingDriver',
        'setHandlingTailLift',
        'setGuard',
        'setConfirmationStep'
      ]),
      /**
       * Called when the user wants to retry the payment
       * after it was erroneous
       * @function retry
       */
      retry () {
        this.dialogs.acceptation.confirmation = false
        this.dialogs.acceptation.error = false
      },
      /**
       * @function close
       */
      close () {
        this.dialogs.acceptation.error = false
      },
      /**
       * Set the expires at datetime with the suggested_expires_at value
       * if available and if the expiration was not already set.
       * @function setSuggestedExpiresAt
       */
      setSuggestedExpiresAt () {
        this.formData.expirationDate = this.$moment(new Date(this.getQuotation.suggested_expires_at))
      },
      updateExpirationTooltipPlacement () {
        const expiresAt = this.$refs.expires_at
        if (expiresAt) {
          const placement = this.$refs.expires_at.getPosition()
          this.expirationTipPlacement = placement === 'top' ? 'bottom' : 'top'
        }
      },
      checkExpirationDate () {
        const { expirationDate } = this.formData
        let parsedDate = this.$moment(expirationDate)

        /**
         * Override the calendar behaviour by setting the current time to H+4 if
         * the date picked is the same day.
         * Set the datetime to the end of the day if we cannot add 4h to the current time
         * otherwise.
         *
         * There is also a flag to ignore the override if it happens on the first date
         * pick.
         *
         * TODO: Remove this behaviour whenever possible, by perhaps replacing the calendar
         * behaviour to avoid it to select the current time by default?
         */
        let hadInitialBehaviour = false
        if (parsedDate.isSame(this.$moment(), 'day') && !this.initialExpirationDate) {
          const isLess4Hours = this.$moment().endOf('day').diff(this.$moment(), 'minutes') <= 4 * 60
          if (!isLess4Hours) {
            this.formData.expirationDate = parsedDate.add(4 * 61, 'minutes')
          } else {
            this.formData.expirationDate = this.$moment().endOf('day')
          }
          parsedDate = this.$moment(this.formData.expirationDate)
          this.initialExpirationDate = true
          hadInitialBehaviour = true
        }

        const isLess4Hours = expirationDate && parsedDate.diff(this.$moment(), 'minutes') <= 4 * 60

        if (isLess4Hours) {
          if (this.$matomo) {
            this.$matomo.trackEvent('Quotations', 'See Short Expiration Time Message')
          }

          Hotjar.tag('Shipment with short delay')

          this.expirationTip = true
        } else {
          this.expirationTip = false

          if (hadInitialBehaviour) return false
        }
      },
      setDefaultValues () {
        this.formData = {
          contactphone: null,
          expirationDate: null,
          shipperReference: null,
          vatNumber: null,
          saveCard: false
        }

        if (this.getPhoneNumber) this.formData.contactPhone = this.getPhoneNumber

        this.country = 'FR'

        if (this.formData.contactPhone) {
          const parsedPhone = parsePhoneNumberFromString(this.formData.contactPhone)
          if (parsedPhone) {
            this.formData.contactPhone = parsedPhone.nationalNumber
            this.country = parsedPhone.country
          }
        }
      },
      changeCountry (country) {
        this.country = country
      },
      /**
       * @typedef PaymentMethod
       * @type {object}
       * @property {string} id
       * @property {string} type
       * @property {boolean} save_card
       *
       * @function requestAcceptation
       * @param {PaymentMethod|null} paymentMethod
       * @returns {Promise<any>}
       */
      requestAcceptation (paymentMethod) {
        const {
          shipperReference,
          pickupReference,
          deliveryReference,
          expirationDate,
          contactPhone
        } = this.formData
        this.formData.saveCard = paymentMethod && paymentMethod.save_card

        const parsedPhone = parsePhoneNumberFromString(contactPhone, this.country)
        const parsedPhoneValue = parsedPhone
          ? parsedPhone.number
          : contactPhone

        const price = this.getQuotationSelectedPrice

        /**
         * TODO: Trim values globally in every axios request.
         * This is a quickfix.
         */
        return QuotationPrice.acceptation({
          cid: this.getCid,
          pid: price.uuid
        }, {
          expires_at: this.hasExpirationDate
            ? this.$moment(expirationDate).format()
            : null,
          shipper_reference: (shipperReference && shipperReference.trim()) || null,
          pickup_reference: (pickupReference && pickupReference.trim()) || null,
          delivery_reference: (deliveryReference && deliveryReference.trim()) || null,
          contact_phone: parsedPhoneValue,
          payment_method: paymentMethod,
          payment_plan: price.payment_plans[0],
          custom_price: this.getCustomPrice ? Math.round(this.getCustomPrice) : null
        })
      },
      /**
       * @function acceptationSuccessHandler
       * @param {any} res
       */
      acceptationSuccessHandler (res) {
        /**
         * Calculus for Google Tag Manager tracking events.
         */
        try {
          const price = this.getQuotationSelectedPrice
          const { uuid } = this.getUserInfos
          const shipmentId = res.data.uuid

          const total = parseFloat(parseFloat(price.total_including_vat / 100).toFixed(2))

          const transactionProducts = price.lines.map(line => ({
            sku: line.key,
            name: line.label,
            category: line.is_option ? 'option' : 'transport',
            price: parseFloat(parseFloat(line.price / 100).toFixed(2)),
            discount: 0,
            quantity: 1
          }))

          /**
           * Emits a e-commerce event to GTM
           */
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            userId: uuid,
            language: this.$i18n.locale,
            event: 'oneTagEvents',
            eventCategory: 'ecommerce/purchase',
            eventAction: 'order confirmation',
            eventLabel: `/checkout/order-confirmation/${shipmentId}`,
            eventValue: total,
            virtualPagePath: this.$route.path,
            virtualPageTitle: this.$t('new-shipment.titles.main'),
            currencyCode: price.currency,
            transactionId: shipmentId,
            transactionAffiliation: '',
            transactionTotal: total,
            transactionTax: parseFloat(parseFloat((price.total_including_vat - price.total) / 100).toFixed(2)),
            transactionShipping: 0,
            transactionProducts
          })
        } catch (e) {
          console.error('Could not track this GTM event:', e)
        }

        Hotjar.tag('Active Shipper')
        Hotjar.event('quotation_accepted')

        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Submission Confirmed', res.data.uuid)
        }

        Shipment.get({
          cid: this.getCid,
          sid: res.data.uuid
        })
          .then(shipmentRes => {
            this.setConfirmationStep(true)
            this.dialogs.acceptation = {
              visible: true,
              confirmation: true,
              shipment: shipmentRes.data
            }
          })
          .finally(() => {
            this.$wait.end('accepting quotation')
          })
      },
      /**
       * @function acceptationErrorHandler
       * @param {any} err
       */
      acceptationErrorHandler (err) {
        if (!err.response) {
          this.$wait.end('accepting quotation')
          return
        }

        const { data } = err.response
        if (!data) {
          this.$wait.end('accepting quotation')
          return
        }

        if (err.response.status === 402) {
          /**
           * Handle Stripe next actions if required
           */
          const paymentMethod = data.payment_method
          switch (paymentMethod.status) {
          case 'requires_auth':
            this.$wait.start('accepting quotation')
            this.stripe.handleCardAction(paymentMethod.secret)
              .then((/** @type {any} */ cardResponse) => {
                if (cardResponse.error) {
                  this.dialogs.acceptation.visible = true
                  this.dialogs.acceptation.error = true

                  this.$wait.end('accepting quotation')
                } else {
                  /**
                   * Got a positive response from Stripe, retry
                   * to submit the acceptation with the payment intent.
                   */
                  this.requestAcceptation({
                    id: cardResponse.paymentIntent.id,
                    type: 'stripe_payment_intent',
                    save_card: this.formData.saveCard
                  })
                    .then(this.acceptationSuccessHandler)
                    .catch(this.acceptationErrorHandler)
                    .finally(() => {
                      this.$wait.end('accepting quotation')
                    })
                }
              })
              .catch((/** @type {any} */ err) => {
                Sentry.captureException(err)
                this.$wait.end('accepting quotation')
              })
            break
          case 'refused':
            this.dialogs.acceptation.visible = true
            this.dialogs.acceptation.error = true

            this.$wait.end('accepting quotation')
            break
          default:
            Sentry.captureException(new Error(`Unhandled payment method status "${paymentMethod.status}"`))
            this.$wait.end('accepting quotation')
            break
          }
        } else {
          this.$wait.end('accepting quotation')

          this.dialogs.acceptation.visible = false
          if (data.error) {
            /**
             * Show the violation message in the input
             */
            if (data.error.violations) {
              handlePropertyPathViolations.call(this, data.error.violations)
            } else {
              const errorMessage = data.error.detail || data.error.title || data.error.message || this.$t('an_error_has_occurred')
              const customAction = (data.error.key === 'command_bus.quotation_price.accept.mapped_external_request_cannot_be_submitted')
                ? {
                  text: this.$t('goto_requests_list'),
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0)
                    window.location = this.getRedirectUrl
                  }

                }
                : null
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right',
                action: customAction
              })
            }
          }

          Sentry.captureException(
            new Error('Could not accept a quotation'),
            {
              extra: {
                err,
                data
              }
            }
          )
        }
      },
      /**
       * @function acceptationSubmitted
       * @param {object|null} [paymentMethod=null]
       */
      acceptationSubmitted (paymentMethod = null) {
        this.$refs.observer.validate()
          .then(async valid => {
            if (!valid) return false

            this.$wait.start('accepting quotation')
            let data
            try {
              const companyRes = await this.retrieveCompany()
              data = companyRes.data
            } catch (err) {
              this.$wait.end('accepting quotation')
              if (!err.response) return
            }

            const { vatNumber } = this.formData

            if (!this.getVatNumber && data.billing && !data.billing.vat_number) {
              try {
                await Company.vatNumber({
                  cid: this.getCid
                }, {
                  vat_number: vatNumber
                })
              } catch (err) {
                const { data } = err.response
                this.dialogs.acceptation.visible = false

                if (data.error.violations) {
                  data.error.violations.forEach(violation => {
                    this.$refs[`${violation.property_path}-provider`].setErrors([
                      violation.message
                    ])
                  })
                } else {
                  if (data.error.title) {
                    showToaster(this, data.error.title, { type: 'error' })
                  } else {
                    showToaster(this, this.$t('an_error_has_occurred'), { type: 'error' })
                  }
                }
                this.$wait.end('accepting quotation')
                return false
              }
            }

            this.requestAcceptation(paymentMethod)
              .then(this.acceptationSuccessHandler)
              .catch(this.acceptationErrorHandler)
          })
      },
      submitted () {
        if (this.$matomo) {
          const { type, payment_plans: paymentPlans } = this.getQuotationSelectedPrice
          this.$matomo.trackEvent('Quotations', 'Initiated Submission', `${type}|${paymentPlans[0]}`)
        }

        this.$refs.observer.validate()
          .then(async valid => {
            if (!valid) return false

            const {
              expirationDate,
              contactPhone
            } = this.formData

            if (!contactPhone || (this.hasExpirationDate && !expirationDate)) {
              return false
            }

            if (this.hasExpirationDate) {
              if (this.$matomo) {
                const parsedDate = this.$moment(expirationDate)
                const isLess4Hours = expirationDate && parsedDate.diff(this.$moment(), 'minutes') <= 4 * 60

                if (isLess4Hours) this.$matomo.trackEvent('Quotations', 'Submitted With Short Expiration Time')
              }
            }

            this.dialogs.acceptation.visible = true
          })
      }
    }
  })
</script>

<style lang="scss">
.new-shipment-informations__title {
  position: relative;
  font-size: 20px;
  margin-bottom: 36px;
}
.new-shipment-informations__title::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 220px;
  height: 1px;
  background-color: $divider;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .new-shipment-informations__references__side::after, .new-shipment-informations__side::after {
    position: absolute;
    content: '';
  }
  .new-shipment-informations__references__side::after {
    right: -0.625rem;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $divider;
  }
  .new-shipment-informations__side::after {
    right: 0;
    bottom: -1.5rem;
    height: 150px;
    width: 260px;
    max-width: 50%;
    background-image: url('~@/assets/img/illustrations/proposal-refuse.svg');
    background-size: cover;
    background-position: right bottom;
    opacity: 0.8;
    z-index: 0;
  }
}
.new-shipment-informations__suggested {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
.new-shipment-informations__tip-popover {
  background-color: #F5F5F5;
  z-index: 5;
}
.new-shipment-informations__tip-popover .popover-body {
  padding: 0.5rem 1rem 1rem 1rem;
}
.new-shipment-informations__tip-popover.bs-popover-bottom .arrow::after {
  border-top-color: #F5F5F5;
}
.new-shipment-informations__tip-popover.bs-popover-top .arrow::after {
  border-bottom-color: #F5F5F5;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .new-shipment-informations__tip-popover {
    display: none !important;
  }
}
</style>
