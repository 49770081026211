<template>
  <fieldset
    class="new-shipment-address-meeting"
  >
    <legend
      id="address-meeting"
      v-text="$t('new-shipment.titles.meeting')"
      class="tw-text-base"
      data-test="legend"
    />

    <ValidationProvider
      rules="required"
      slim
    >
      <template slot-scope="{ errors, invalid }">
        <ui-fat-radio-group
          v-model="rdvValue"
          :items="items"
          aria-labelledby="address-meeting"
          class="tw-flex tw-flex-col md:tw-flex-row tw-mb-5"
          data-test="radio"
        >
          <template slot-scope="{ item, active, keydown }">
            <ui-fat-radio-item
              :title="item.title"
              :active="active"
              :error="invalid && errors.length > 0"
              class="tw-w-full md:tw-w-1/2 not-last:tw-mb-4 not-last:md:tw-mr-4 not-last:md:tw-mb-0"
              @keydown.native="keydown"
              @click.native="rdvValue = item.value"
            >
              <div
                v-text="item.title"
                class="new-shipment-address-meeting__radio-title tw-text-sm tw-mb-1 tw-font-medium"
                data-test="title"
              />
              <div
                v-text="item.content"
                class="new-shipment-address-meeting__radio-content tw-text-xs"
                data-test="content"
              />
            </ui-fat-radio-item>
          </template>
        </ui-fat-radio-group>
      </template>
    </ValidationProvider>

    <template
      v-if="rdvValue"
    >
      <new-shipment-alert
        data-test="meeting-explanation"
      >
        <p
          v-text="$t('new-shipment.paragraphs.meeting.explanation')"
          class="tw-mb-0"
        />
      </new-shipment-alert>

      <ValidationProvider
        :name="$t('new-shipment.fields.meeting')"
        rules="required|max:4000"
        slim
      >
        <template slot-scope="{ invalid, errors }">
          <ctk-input-textarea
            id="meeting"
            v-model="commentValue"
            :label="$t('new-shipment.labels.meeting')"
            :error="invalid && errors.length > 0"
            :hint="errors[0]"
            name="meeting"
            class="tw-mb-12"
            required
          />
        </template>
      </ValidationProvider>
    </template>
  </fieldset>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useI18n from '@/composables/useI18n'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'
  import UiFatRadioItem from '@/components/UI/FatRadioGroup/FatRadioItem/index.vue'
  import CtkInputTextarea from '@/components/CtkInputs/CtkInputTextarea/index.vue'
  import NewShipmentAlert from '@/views/Shippers/NewShipment/components/NewShipmentAlert/index.vue'

  export default defineComponent({
    name: 'NewShipmentAddressMeeting',
    components: {
      UiFatRadioGroup,
      UiFatRadioItem,
      CtkInputTextarea,
      NewShipmentAlert
    },
    props: {
      rdv: {
        type: Boolean,
        default: null
      },
      comment: {
        type: String,
        default: null
      },
      error: {
        type: String,
        default: null
      }
    },
    emits: [
      'update:rdv',
      'update:comment'
    ],
    setup (props) {
      const i18n = useI18n()
      const { state: rdvValue } = useModelGetterSetter(props, 'rdv')
      const { state: commentValue } = useModelGetterSetter(props, 'comment')

      const items = [
        {
          title: i18n.value.t('new-shipment.values.meeting.with_meeting'),
          content: i18n.value.t('new-shipment.paragraphs.meeting.with_meeting'),
          value: true
        },
        {
          title: i18n.value.t('new-shipment.values.meeting.without_meeting'),
          content: i18n.value.t('new-shipment.paragraphs.meeting.without_meeting'),
          value: false
        }
      ]

      return {
        rdvValue,
        commentValue,
        items
      }
    }
  })
</script>

<style lang="scss">
.new-shipment-address-meeting .ui-fat-radio-item:not(:last-child).active::after {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  bottom: -6px;
  content: '';
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.new-shipment-address-meeting .ui-fat-radio-item:not(:last-child):hover.active:not(:disabled)::after, .new-shipment-address-meeting .ui-fat-radio-item:not(:last-child):focus-visible.active:not(:disabled)::after {
  background-color: lighten($info, 5%);
}
.new-shipment-address-meeting .ui-fat-radio-item:not(.active) .new-shipment-address-meeting__radio-title {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.new-shipment-address-meeting .ui-fat-radio-item:not(.active) .new-shipment-address-meeting__radio-content {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
</style>
